import { Component, Input } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ApplicationService } from 'src/app/core/service/application.service';
import { ModalService } from 'src/app/core/service/modal.service';
import { SpinnerService } from 'src/app/core/service/spinner.service';

@Component({
  selector: 'app-deposit-accounts',
  templateUrl: './deposit-accounts.component.html',
  styleUrls: ['./deposit-accounts.component.css']
})
export class DepositAccountsComponent {
  @Input() ssoId: 'string'
  public title: string = '';
  public viewAll: boolean = false;
  public showTileView: boolean = true;
  public balanceTrnsfrData: any = [];
  public totalAvailableBalance: any;
  public toggleView: boolean = false;
  public totalPages: number;
  public displaySpinner: boolean = false;
  public errorMessage: string;
  public isError: boolean = false;

  // table pages
  totalTablePages: number = 1;

  firstThreeAcc: any = [];
  restAcc: any = [];
  // Material Pagination  
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  public showLargeScreens: boolean = false;
  pageEvent: PageEvent;
  public resizedWindow: boolean = false;
  public spinnerTitle: string = 'Loading Deposit Accounts...';

  constructor(private appService: ApplicationService, protected modalService: ModalService,
    private spinnerService: SpinnerService
   ) {
    this.title = this.appService.title
    this.resizedWindow = this.modalService.setWindow()
  }

  ngOnInit(): void {
    this.getDepositsData({ pageIndex: 0, pageSize: 12 });
  }

  get isTableVisible() {
    if (this.appService.getViewToggleValue()) {
      this.showTileView = false;
    }
    return this.appService.getViewToggleValue()
  }


  public getDepositsData: any = (value: any) => {
    this.displaySpinner = true;
    this.appService.getFunction(
      `v1/accounts/DEPOSITACCTS?pageOffset=${((value['pageIndex']) * value['pageSize']) + 1}&pageLimit=${value['pageSize']}`,
      { 'SSO_ID': `${this.ssoId}` }
    ).subscribe((response) => {
      this.displaySpinner = false;
      if (response.status === 200) {
        this.balanceTrnsfrData = response?.data;
        this.totalAvailableBalance = this.balanceTrnsfrData?.accountSummaries.reduce((b: any, a: any) => b + a.availableBalance, 0);
        this.firstThreeAcc = this.balanceTrnsfrData?.accountSummaries.slice(0, 3);
        this.restAcc = this.balanceTrnsfrData?.accountSummaries.slice(3);
        this.checkTileViewValue()
      }
    }, error => {
      this.isError = true;
      this.displaySpinner = false;
      this.errorMessage = `**Unable to display deposits accounts, please check the balances in ${this.appService.appName}**`;
      console.error('Error fetching loan data:', error);
    })
  }

  public getTransactionList: any = (accountInfo: any) => {
    this.spinnerService.setText('Please wait...');
    this.spinnerService.showSpinner();
    const accountDetails = {
      // clientName: accountInfo.clientAccountName,
      accountName: accountInfo.accountName,
      accountNumber: accountInfo.accountNumber,
      accountFilter: accountInfo.accountFilter,
      routingNumber: accountInfo.routingNumber,
      availableBalance: accountInfo.availableBalance,
      accountType: accountInfo.accountType,
      ssoId: this.ssoId
    }

    if (!accountInfo.isRealTimeAccount) {
      this.openViewMore(accountDetails);
      return;
    }

    this.modalService.setAccountValues(accountDetails, 'deposits');
    this.modalService.setWindow();
    this.modalService.openDialog().then(() => {
      // will be hidden in transaction comp

    }).catch(() => {
      this.spinnerService.hideSpinner();
    })
  }

  public checkViewAll: any = () => {
    this.viewAll = !this.viewAll
    this.checkTileViewValue()
  }

  public checkTileViewValue: any = () => {
    this.showTileView = this.balanceTrnsfrData.totalRows > 12 ? false : true
  }

  handleTablePagination(actionType: any) {
    this.getDepositsData(actionType);
  }

  openViewMore(accountDetail: any) {
    const getSsoIdArr = this.appService.getSsoIdArrValue()
    const selectSsoId = getSsoIdArr.find((ssoIdData: any) => ssoIdData['ssoId'] === accountDetail['ssoId'])

    const openAOEvent = new CustomEvent("open-access-optima", {
      detail: { from: "btl-application", ssoIdData: selectSsoId }
    });
    dispatchEvent(openAOEvent);
  }
}
