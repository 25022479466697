import { Component, OnInit, ViewChild } from '@angular/core';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { BalanceTransferComponent } from './components/balance-transfer/balance-transfer.component';

@Component({
  selector: 'app-account-summary-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title: string = "comm-sso-account-summary-mfe";
  currentCompName: string = "";
  customClass: string = "";
  shouldDisplayTheComps: boolean = true;
  @ViewChild(DynamicComp, { static: true }) compRef!: DynamicComp;

  compHash: { name: string, compRef: any }[] = [
    { name: "balance-transfer", compRef: BalanceTransferComponent }
  ];

  ngOnInit() {
    singleSpaPropsSubject.subscribe((props: any) => {
      this.customClass = props.customClass;
    });
  }

  handleError(value: boolean) {
    this.shouldDisplayTheComps = !value;
  }

  injectComponent() {
    if (!this.currentCompName) return;

    const componentObj = this.compHash.find(comp => comp.name === this.currentCompName);
    if (!componentObj) return;

    const viewRef = this.compRef.viewContainerRef;
    viewRef.clear();
    viewRef.createComponent(componentObj.compRef);
  }

}
