<app-loading-spinner [displaySpinner]="displaySpinner" [spinnerTitle]="spinnerTitle"></app-loading-spinner>
<div class="deposit pt-2" *ngIf="firstThreeAcc.length && !isError">
    <div class="cardView">
        <div class="deposit-heading">
            <h5 *ngIf="balanceTrnsfrData.totalRows > 12" class="font-weight-bold">Deposit Accounts</h5>
            <h5 *ngIf="balanceTrnsfrData.totalRows < 12" class="font-weight-bold">
                Deposit Accounts Balance - {{totalAvailableBalance | currency: 'USD'}}
            </h5>
        </div>

        <div class="row overflow-hidden" *ngIf="((balanceTrnsfrData.totalRows <= 12) || (firstThreeAcc.length <= 3 && !viewAll)) && !isTableVisible">
            <div class="col-lg-4 col-md-4 mb-3" *ngFor="let item of firstThreeAcc">
                <div class="card-text m-auto">
                    <div class="btl-card cbensightenevent" cbdata-type="button"
                    cbdata-reason="View More Deposit Transactions" (click)="getTransactionList(item)">
                        <div class="btl-details">
                            <p class="text-left mb-0">
                                {{item.accountName | textEllipsis}}
                            </p>
                            <p class="text-left mt-1 mb-1 btl-acc-number-card">
                                {{item.accountNumber | trimAccount}}
                            </p>
                            <p class="btl-balance text-left">
                                {{item.availableBalance | currency: 'USD' | textEllipsis}}
                            </p>
                            <span class="select-right-card-icon">&#8250;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p (click)="checkViewAll()" class="displayServicesBtn" *ngIf="restAcc.length && !isTableVisible">
        <span *ngIf="!viewAll">+ View All</span>
        <span *ngIf="viewAll">- View Less</span>
    </p>

    <div class="cardView" *ngIf="(balanceTrnsfrData.totalRows <= 12 && !isTableVisible) && viewAll">
        <div class="row overflow-hidden">
            <div class="col-lg-4 col-md-4 mb-3" *ngFor="let item of restAcc;">
                <div class="card-text m-auto">
                    <div class="btl-card cbensightenevent" cbdata-type="button" cbdata-reason="View More Deposit Transactions"
                    (click)="getTransactionList(item)">
                        <div class="btl-details">
                            <p class="text-left mb-0">
                                {{item.accountName | textEllipsis}}
                            </p>
                            <p class="text-left mt-1 mb-1 btl-acc-number-card">
                                {{item.accountNumber | trimAccount}}
                            </p>
                            <p class="btl-balance text-left">
                                {{item.availableBalance | currency: 'USD' | textEllipsis}}
                            </p>
                            <span class="select-right-card-icon">&#8250;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="tableView mb-4" *ngIf="(viewAll && balanceTrnsfrData.totalRows > 12)|| isTableVisible">
        <div class="tableBorder" [ngClass]="resizedWindow ? '' : 'smallTableBorder' ">
            <div class="table-responsive btl-table depositsTable d-sm-block">
                <table class="table table-hover" aria-describedby="Deposit Accounts Table">
                    <thead>
                        <tr>
                            <th>Account Name</th>
                            <th *ngIf="resizedWindow">Account Number</th>
                            <th>Available Balance</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let btlData of balanceTrnsfrData.accountSummaries" class="pe-cursor cbensightenevent"
                        cbdata-type="button" cbdata-reason="View More Deposit Transactions"(click)="getTransactionList(btlData)">
                            <td class="text-wrap">
                                <span>
                                    {{btlData.accountName}}
                                </span>
                            </td>
                            <td *ngIf="resizedWindow">
                                <span>{{btlData.accountNumber | trimAccount }}</span>
                            </td>
                            <td>
                                <span>{{btlData.availableBalance | currency : 'USD'}}</span>
                            </td>
                            <td>
                                <span class="select-right-table-icon">&#8250;</span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">
                                <div class="table-pagination">
                                    <mat-paginator [length]="balanceTrnsfrData.totalRows"
                                        [pageSize]="balanceTrnsfrData.rowsPerPage" [pageSizeOptions]="[12,24,36,60]"
                                        [pageIndex]="depositPageIndex" (page)="handleTablePagination($event)"></mat-paginator>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>

</div>

<div *ngIf="isError" class="text-center p-5">{{errorMessage}}</div>