import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimAccount'
})
export class TrimAccountPipe implements PipeTransform {

  
  transform(value: string) {
    if (value.length > 0) {
      return 'xxxxxx' + value.substring(value.length - 4, value.length);
    }
  }

}