import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { LoanAccountsComponent } from './components/loan-accounts/loan-accounts.component';
import { DepositAccountsComponent } from './components/deposit-accounts/deposit-accounts.component';
import { BalanceTransferComponent } from './components/balance-transfer/balance-transfer.component';
import { RouterModule } from '@angular/router';
import { TransactionViewComponent } from './components/transaction-view/transaction-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TextEllipsisPipe } from './core/pipe/text-ellipsis.pipe';
import { TrimAccountPipe } from './core/pipe/account-number.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ModalSpinnerComponent } from './components/modal-spinner/modal-spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    DynamicComp,
    LoanAccountsComponent,
    DepositAccountsComponent,
    BalanceTransferComponent,
    TransactionViewComponent,
    TextEllipsisPipe,
    TrimAccountPipe,
    LoadingSpinnerComponent,
    ModalSpinnerComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
