import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TransactionViewComponent } from 'src/app/components/transaction-view/transaction-view.component';
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public accountDetails: any = {};
  public accountType: string = '';
  public showBigScreen: boolean = false;

  constructor(private openPopup: MatDialog) { }

  setAccountValues(accountDetails: any, accountType: string) {
    this.accountDetails = accountDetails;
    this.accountType = accountType;
  }

  getAccountValues() {
    return {
      accountDetails: this.accountDetails,
      accountType: this.accountType
    }
  }

  setWindow() {
    if (window.innerWidth > 600) {
      this.showBigScreen = true;
      return this.showBigScreen
    } else if (window.innerWidth <= 600) {
      this.showBigScreen = false;
      return this.showBigScreen
    } else {
      this.showBigScreen = false;
      return this.showBigScreen
    }
  }

  openDialog() {
    const config = {
      maxWidth: `${this.showBigScreen}` === 'true' ? '70vw' : '90vw',
      width: `${this.showBigScreen}` === 'true' ? '70vw' : '100%',
      // minHeight: 'calc(100%-10px)',
      // height: '100%',
      disableClose: true
    }
    return this.openPopup.open(TransactionViewComponent, config).afterOpened().toPromise();
  }
}
