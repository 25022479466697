import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApplicationService } from 'src/app/core/service/application.service';
import { ModalService } from 'src/app/core/service/modal.service';
interface LINKCREDINFO {
  companyid: string;
  user_disabled: boolean;
  user_locked: any;
  userid: string;
  under_maintenance: boolean;
  applicationName: string;
}


@Component({
  selector: 'app-balance-transfer',
  templateUrl: './balance-transfer.component.html',
  styleUrls: ['./balance-transfer.component.css']
})
export class BalanceTransferComponent implements OnInit {
  public toggleView: boolean = false;
  public displayLoans: boolean = false;
  public displayDeposits: boolean = false;
  private ssoId: string = ''
  public showError: boolean = false;
  public hideBtl: boolean = false;
  public hideAccountSummary: boolean = false;
  public errorMessage: string = '';
  public ssoIDArr: any[] = [
    
  ];

  // {ssoId: 'SURESHG_UATALL_MMGPS',companyId: '123'}
  public resizedWindow: boolean = false;

  @Output() errorOrNoData = new EventEmitter<boolean>();

  constructor(private appService: ApplicationService, private modalService: ModalService) {
    this.resizedWindow = this.modalService.setWindow()

  }

  ngOnInit(): void {
    this.getCompanyInfo()
  }

  private getCompanyInfo: any = () => {
    const getlinkCredFromSession = JSON.parse(JSON.stringify(sessionStorage.getItem('linkedCredentialInfo')))
    const linkCredInfo = JSON.parse(getlinkCredFromSession)

    if (linkCredInfo) {      
      for (let [key, value] of Object.entries(linkCredInfo)) {
        const linkCredsValue = value as LINKCREDINFO;
        const isMMGPS = key.includes('MMGPS');
        const isCashflow = key.includes('CASHFLOW');
        const isCompanyID = linkCredsValue.companyid;
        const isNotDisabled = linkCredsValue.user_disabled === false;
        const isNotLocked = linkCredsValue.user_locked === '' || linkCredsValue.user_locked === false;
        const isUnderMaintenance = linkCredsValue.under_maintenance === false

        if (isCompanyID && isUnderMaintenance && isNotDisabled && isNotLocked && (isMMGPS || isCashflow)) {
          let appName = isMMGPS ? "accessoptima" : 'cashflow'
          linkCredsValue['applicationName'] = appName;
          this.appService.appName = linkCredsValue['applicationName']= isMMGPS ? 'accessOPTIMA' : 'Cash Flow Essentials';
          this.ssoIDArr.push({ ssoId: key, companyId: linkCredsValue.companyid, userId: linkCredsValue.userid, applicationName: appName })
        }
      }
      this.appService.setSsoIdArrValue(this.ssoIDArr)
      this.ssoId = this.ssoIDArr[0]['ssoId']
      this.getAccountsType();
    }
  }

  private getAccountsType: any = () => {
    this.appService.getFunction('v1/accounts/types', { 'SSO_ID': this.ssoId }).subscribe((response: any) => {
      if (response.status === 200) {
        this.showError = false;
        const responseData = response.data;
        responseData.forEach((element: any) => {
          if ((element.sectionType).toLowerCase() === 'deposit') {
            this.displayDeposits = true
          }
          if ((element.sectionType).toLowerCase() === 'loan') {
            this.displayLoans = true
          }
        });
      }
      if (response.status !== 200) {
        if (this.ssoIDArr.length === 1) {
          this.hideBtl = true
        }
        this.showError = true
      }
    }, error => {
      console.error(error);
        this.hideBtl = true;
        this.hideAccountSummary = true;
        this.errorMessage = `**Unable to display accounts, please check the balances in ${this.appService.appName}**`;
    })
  }

  public toggleViewChanged: any = () => {
    this.appService.setViewToggleValue(this.toggleView)
  }

  public dropdownChange(ssoId: string) {
    this.ssoId = ssoId;
    this.displayDeposits = false;
    this.displayLoans = false;
    this.getAccountsType();
  }

}
