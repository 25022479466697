import { Component } from '@angular/core';
import { Const } from 'src/app/core/enums/const.enums';
import { ApplicationService } from 'src/app/core/service/application.service';
import { ModalService } from 'src/app/core/service/modal.service';
import { SpinnerService } from 'src/app/core/service/spinner.service';


@Component({
  selector: 'app-transaction-view',
  templateUrl: './transaction-view.component.html',
  styleUrls: ['./transaction-view.component.css']
})
export class TransactionViewComponent {
  public transactionList: any = [];
  public transactionDetails: any;
  public accountType: string = '';
  public pageNumber: number = 0;
  public btnLabel: string = '';
  public errorMsg: string = '';
  public accountDetails: any;
  public enableNextBtn: boolean = false;
  public enablePrevBtn: boolean = false;
  public showNoDataError: boolean = false;
  public resizedWindow: boolean = false;


  constructor(private appService: ApplicationService, private modalService: ModalService,
    private spinnerService: SpinnerService
  ) {
    this.resizedWindow = this.modalService.setWindow();
  }

  ngOnInit(): void {
    const getAccoundtData = this.modalService.getAccountValues()
    this.accountDetails = getAccoundtData.accountDetails
    this.accountType = getAccoundtData.accountType
    this.getViewTransactionList();
    this.getLabel();
  }

  getDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    const _month = (month + 1).toString().padStart(2, '0');
    const _date = date.toString().padStart(2, '0');
    return `${year}-${_month}-${_date}`;
  }

  public getViewTransactionList: any = () => {

    let url = this.accountType === 'loans' ? 'v1/transactions/loan' : 'v1/transactions/deposit'
    const reqBody = this.createRequestBody();

    this.appService.postFunction(`${url}`, reqBody, `${this.accountDetails['ssoId']}`)
      .subscribe((response: any) => {
        this.spinnerService.hideSpinner();
        this.handleResponse(response)
      });
  }

  public createRequestBody () {
    const reqBody: any = {
      accountNumber: this.accountDetails.accountNumber,
      // startDate: '2023-11-14',
      // endDate: '2023-21-22',
      pageNumber: this.pageNumber,
      pageLimit: 10
    };

    if (this.accountType === "deposits") {
      reqBody['routingNumber'] = this.accountDetails.routingNumber;
      reqBody['transactionType'] = "ALL_TRANSACTIONS";
      reqBody['accountType'] = this.accountDetails.accountType;
    }

    return reqBody
  }

  public handleResponse(response: any) {
    if(response.status === 200 || response.status === 204) {
      this.processSuccessfulResponse(response)
    } else {
      this.showNoDataError = false
    }
    this.transactionDetails = this.accountDetails
  }

  public processSuccessfulResponse(response: any){
    if (response?.data?.transaction && Array.isArray(response?.data?.transaction)) {
      const responseData = response.data;
      this.enablePrevBtn = this.pageNumber > 0
      this.enableNextBtn = `${(responseData['moreRecords'])}`.toLowerCase() === 'yes';
      this.transactionList = response?.data?.transaction;
      this.showNoDataError = this.transactionList.length === 0;
    } else {
      this.showNoDataError = true
    }
  }

  openViewMore() {
    const getSsoIdArr = this.appService.getSsoIdArrValue()
    const selectSsoId = getSsoIdArr.find((ssoIdData: any) => ssoIdData['ssoId'] === this.accountDetails['ssoId'])

    const openAOEvent = new CustomEvent("open-access-optima", {
      detail: { from: "btl-application", ssoIdData: selectSsoId }
    });
    dispatchEvent(openAOEvent);
    this.destroyData()
  }

  public destroyData: any = () => {
    this.transactionList = [];
  }


  handleTablePagination(actionType: string) {
    if (actionType === 'next') {
      this.pageNumber = this.pageNumber + 1
    }
    if (actionType === 'previous' && this.pageNumber > 0) {
      this.pageNumber = this.pageNumber - 1
    }
    this.getViewTransactionList();
  }

  public getLabel() {
    this.errorMsg = `${this.accountDetails['ssoId']}`.includes('MMGPS') ? Const.aoErrorMsg : Const.cfeErrorMsg;
    this.btnLabel = `${this.accountDetails['ssoId']}`.includes('MMGPS') ? 'accessOptima' : 'Cash Flow Essentials';
  }
}
